import React, { createContext, useState, useContext, useEffect } from "react";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "app/hooks/useSaveToLocalStorage";

// Create the AuthContext
const AuthContext = createContext();

// Define the AuthProvider
export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    name: "",
    id: "",
    jwt: "",
    email: "",
    phone: "",
    country: "",
    // gender: "",
    avatarUrl: "",
    profession: { id: "", label: "" },
  });

  // Load saved authentication state from localStorage on initial render
  useEffect(() => {
    const savedAuthState = getFromLocalStorage("GeoLanes");
    if (savedAuthState && savedAuthState.authState) {
      setAuthState(savedAuthState.authState);
    } else {
      // Set guest user info if no auth state is found
      setAuthAsGuest();
    }
  }, []);

  // Set guest user info
  const setAuthAsGuest = () => {
    const guestAuthState = {
      name: "Guest",
      id: "guest",
      jwt: null,
      email: "",
      phone: "",
      // gender: "",
      country: "",
      avatarUrl: "",
      profession: { id: "", label: "Guest" },
    };
    setAuthState(guestAuthState);
    saveToLocalStorage("GeoLanes", { authState: guestAuthState });
  };

  // Set authentication info and store it in localStorage
  const setAuthInfo = (authInfo) => {
    setAuthState(authInfo);
    saveToLocalStorage("GeoLanes", { authState: authInfo });
  };

  // Handle logout by resetting auth state and clearing localStorage
  const logout = () => {
    setAuthAsGuest();
  };

  return (
    <AuthContext.Provider
      value={{ authState, setAuthInfo, logout, setAuthAsGuest }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the Auth context
export const useAuth = () => useContext(AuthContext);
