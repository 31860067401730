import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Loading from "app/components/shared/loading/Loading";
import { useAuth } from "app/context/useAuthContext";
import { toast } from "react-toastify";

// pages
const Login = lazy(() => import("app/containers/auth/Login"));
const FavoritesMain = lazy(() =>
  import("app/containers/favorites/FavoritesMain")
);
const Register = lazy(() => import("app/containers/auth/Register"));
const Success = lazy(() => import("app/containers/auth/Success"));
const SuccessNewPassword = lazy(() =>
  import("app/containers/auth/SuccessNewPassword")
);
const ResetPassword = lazy(() => import("app/containers/auth/ResetPassword"));
const ResetCodeSent = lazy(() => import("app/containers/auth/ResetCodeSent"));
const Otp = lazy(() => import("app/containers/auth/Otp"));
const NewPassword = lazy(() => import("app/containers/auth/NewPassword"));
const BlockedAccount = lazy(() => import("app/containers/auth/BlockedAccount"));
const OnbAuth = lazy(() => import("app/containers/auth/OnbAuth"));
const ViewLocationDetails = lazy(() =>
  import("app/containers/location/ViewLocationDetails")
);
const Interests = lazy(() => import("app/containers/auth/Interests"));
const TourSuccess = lazy(() => import("app/containers/tours/TourSuccess"));
const Locations = lazy(() => import("app/containers/auth/Locations"));
const FacebookAuth = lazy(() => import("app/containers/auth/FacebookAuth"));
const PageUnderConstruction = lazy(() =>
  import("app/components/shared/construction/PageUnderProcess.js")
);
const AccountsAuth = lazy(() => import("app/containers/auth/AccountsAuth"));
const AppleAuth = lazy(() => import("app/containers/auth/AppleAuth"));
const ViewAllVentures = lazy(() =>
  import("app/containers/tours/ViewAllVentures")
);
const AddTourLocation = lazy(() =>
  import("app/containers/tours/AddTourLocation")
);
const LandingPage = lazy(() => import("app/containers/home/LandingPage"));
const VoiceSearch = lazy(() => import("app/containers/home/VoiceSearch"));
const ToursMain = lazy(() => import("app/containers/tours/ToursMain"));
const CreatePost = lazy(() => import("app/containers/posts/CreatePost"));
const NotificationsMain = lazy(() =>
  import("app/containers/notifications/NotificationsMain")
);
const ResetPasswordOnUpdate = lazy(() =>
  import("app/components/auth/updateProfile/ResetPasswordOnUpdate")
);
const UpdateInterests = lazy(() =>
  import("app/containers/auth/UpdateInterests")
);
const TourPreferences = lazy(() =>
  import("app/containers/tours/TourPreferences")
);
const UpdateProfile = lazy(() => import("app/containers/auth/UpdateProfile"));

const routes = [
  { path: "/", element: <LandingPage />, isPrivate: false },
  { path: "/plan-tour", element: <VoiceSearch />, isPrivate: false },
  {
    path: "/update-profile/ResetPassword",
    element: <ResetPasswordOnUpdate />,
    isPrivate: true,
  },
  {
    path: "/update-profile/UpdateInterests",
    element: <UpdateInterests />,
    isPrivate: true,
  },
  { path: "/update-profile", element: <UpdateProfile />, isPrivate: true },
  { path: "/login", element: <Login />, isPrivate: false },
  {
    path: "/tours/:id/ventures",
    element: <ViewAllVentures />,
    isPrivate: true,
  },

  { path: "/favorites", element: <FavoritesMain />, isPrivate: true },
  { path: "/tour-success", element: <TourSuccess />, isPrivate: true },
  {
    path: "/view-location-details/:tourId?/:id",
    element: <ViewLocationDetails />,
    isPrivate: false,
  },
  { path: "/register", element: <Register />, isPrivate: false },
  {
    path: "/Success-new-password",
    element: <SuccessNewPassword />,
    isPrivate: true,
  },
  { path: "/account-blocked", element: <BlockedAccount />, isPrivate: false },
  { path: "/tours", element: <ToursMain />, isPrivate: true },
  { path: "/notifications", element: <NotificationsMain />, isPrivate: true },
  {
    path: "/tours/:tourId?/attractions/:attractionId?/add-location",
    element: <AddTourLocation />,
    isPrivate: true,
  },
  {
    path: "/tours/:tourId?/attractions/:attractionId?",
    element: <TourPreferences />,
    isPrivate: false,
  },

  { path: "/reset-password", element: <ResetPassword />, isPrivate: false },
  { path: "/reset-code-sent", element: <ResetCodeSent />, isPrivate: false },
  { path: "/reset/otp", element: <Otp />, isPrivate: false },
  { path: "/reset/password", element: <NewPassword />, isPrivate: false },
  { path: "/onboarding", element: <OnbAuth />, isPrivate: true },
  {
    path: "/register-success",
    element: <Success />,
    isPrivate: true,
  },
  { path: "/interests", element: <Interests />, isPrivate: true },
  { path: "/locations", element: <Locations />, isPrivate: true },
  { path: "/share-post", element: <CreatePost />, isPrivate: true },
  { path: "/facebook-auth", element: <FacebookAuth />, isPrivate: false },
  { path: "/accounts-auth", element: <AccountsAuth />, isPrivate: false },
  { path: "/appleId-auth", element: <AppleAuth />, isPrivate: false },
  // { path: "/tour-preferences", element: <TourPreferences />, isPrivate: false },
  { path: "*", element: <PageUnderConstruction />, isPrivate: false },
];

const ProtectedRoute = ({ element, isPrivate }) => {
  const { authState } = useAuth();
  const navigate = useNavigate();

  if (isPrivate && authState.id === "guest") {
    toast.error("You're not allowed to access this page. Please login.");
    navigate(-1);
    return null;
  }

  return <Suspense fallback={<Loading />}>{element}</Suspense>;
};

const RoutesMain = () => {
  return (
    <Router>
      <Routes>
        {routes.map(({ path, element, isPrivate }) => (
          <Route
            key={path}
            path={path}
            element={<ProtectedRoute element={element} isPrivate={isPrivate} />}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default RoutesMain;
