// utils/localStorage.js
export const saveToLocalStorage = (appName, newData) => {
  // Get existing data from local storage
  const existingData = JSON.parse(localStorage.getItem(appName)) || {};

  // Merge the new data with existing data
  const updatedData = {
    ...existingData,
    ...newData,
  };

  // Save the updated object back to local storage
  localStorage.setItem(appName, JSON.stringify(updatedData));
};

export const getFromLocalStorage = (appName) => {
  return JSON.parse(localStorage.getItem(appName)) || {};
};