import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { AuthProvider, useAuth } from "app/context/useAuthContext";
import { TourPreferenceProvider } from "app/context/TourPreferenceContext";
import { LocationProvider } from "app/context/useAddLocationContext";

import "app/assets/font/stylesheet.css";
import "app/assets/css/reset.css";
import "app/assets/css/index.css";
import "react-toastify/dist/ReactToastify.css";
import "flag-icons/css/flag-icons.min.css";


const gqlEndPoint = process.env.REACT_APP_API_URL;

const ApolloSetup = ({ children }) => {
  const { authState } = useAuth() || {};
  const token = authState ? authState.jwt : null;

  // Apollo Client setup
  const client = new ApolloClient({
    uri: gqlEndPoint,
    cache: new InMemoryCache(),
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

// Create the root for rendering
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <TourPreferenceProvider>
        <LocationProvider>
          <ApolloSetup>
            <App />
          </ApolloSetup>
        </LocationProvider>
      </TourPreferenceProvider>
    </AuthProvider>
    <ToastContainer />
  </React.StrictMode>
);
