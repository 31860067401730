import React from "react";
import LogoIcon from "app/assets/images/loading/GLLOGO.svg";

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-[100dvh] w-full">
      <img className="max-w-[400px]" src={LogoIcon} alt="logo" />
    </div>
  );
};

export default Loading;
