import React, { createContext, useState, useCallback } from "react";

export const TourPreferenceContext = createContext();

export const TourPreferenceProvider = ({ children }) => {
  const [pet, setPet] = useState(false);
  const [child, setChild] = useState(false);
  const [lgtbq, setLgtbq] = useState(false);
  const [radius, setRadius] = useState(0);
  const [rating, setRating] = useState(0);
  const [options, setOptions] = useState([]);
  const [selectedOptionsSightseeing, setSelectedOptionsSightseeing] = useState([]);
  const [justRecommended, setJustRecommended] = useState(false);
  const [maxCentreOffset, setMaxCentreOffset] = useState(0);
  const [justAlphabetical, setJustAlphabetical] = useState(false);
  const [justDowntownFirst, setJustDowntownFirst] = useState(false);

  // Function to export filters as an object
  const exportFilters = useCallback(() => {
    return {
      pet,
      child,
      lgtbq,
      radius,
      rating,
      selectedOptionsSightseeing,
      justRecommended,
      maxCentreOffset: parseInt(maxCentreOffset, 10),
      justAlphabetical,
      justDowntownFirst,
    };
  }, [
    pet,
    child,
    lgtbq,
    radius,
    rating,
    selectedOptionsSightseeing,
    justRecommended,
    maxCentreOffset,
    justAlphabetical,
    justDowntownFirst,
  ]);

  return (
    <TourPreferenceContext.Provider
      value={{
        pet,
        setPet,
        child,
        setChild,
        lgtbq,
        setLgtbq,
        radius,
        setRadius,
        rating,
        setRating,
        options,
        setOptions,
        selectedOptionsSightseeing,
        setSelectedOptionsSightseeing,
        justRecommended,
        setJustRecommended,
        maxCentreOffset,
        setMaxCentreOffset: (value) => setMaxCentreOffset(parseInt(value, 10)),
        justAlphabetical,
        setJustAlphabetical,
        justDowntownFirst,
        setJustDowntownFirst,
        exportFilters,
      }}
    >
      {children}
    </TourPreferenceContext.Provider>
  );
};
